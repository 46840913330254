import React, { useState, Fragment } from "react";
import "./form-section.scss";
import { Heading } from "../Heading/Heading";
import { Button } from "../Button/Button";
import Obfuscate from "react-obfuscate";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import {Trans, useTranslation } from "gatsby-plugin-react-i18next";
import mapIcon from '../../images/icons/map.svg';
import mailIcon from '../../images/icons/mail.svg';
import phoneIcon from '../../images/icons/phone.svg';


const phoneRegExp = /^[0-9 ()+-]+$/;
const schema = yup
  .object({
    name: yup
      .string()
      .required(<Trans i18nKey="contact.form.validation.name.required"></Trans>)
      .min(3, <Trans i18nKey="contact.form.validation.name.min"></Trans>),
    email: yup
      .string()
      .email(<Trans i18nKey="contact.form.validation.email.not-valid"></Trans>)
      .required(<Trans i18nKey="contact.form.validation.email.required"></Trans>),
    phone: yup
      .string()
      .required(<Trans i18nKey="contact.form.validation.phone.required"></Trans>)
      .matches(phoneRegExp, <Trans i18nKey="contact.form.validation.phone.not-valid"></Trans>)
      .min(5, <Trans i18nKey="contact.form.validation.phone.min"></Trans>),
    country: yup.string(),
    company: yup.string(),
    subject: yup.string(),
    message: yup.string(),
    gdpr: yup.boolean().oneOf([true],  <Trans i18nKey="contact.form.validation.gdpr"></Trans>),
  })
  .required();




const FormSection = ({ title }) => {
  const {
    register,
    handleSubmit,
    onError,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const showSuccessMessage = () => {
    setIsSuccess(true);
    setTimeout(() => setIsSuccess(false), 5000);
  };

  const handleSubmitSuccessful = () => {
    setLoading(false);
    showSuccessMessage();

    reset({
      name: "",
      email: "",
      phone: "",
      company: "",
      country: "",
      subject: "",
      message: "",
      gdpr: false,
    });
  };

  const onSubmit = (data) => {
    const formData = new FormData();
    for (const key in data) {
      if (key === "field") {
        formData.append(key, data[key][1]);
      } else {
        formData.append(key, data[key]);
      }
    }

    axios
      .post(`${process.env.GATSBY_API_URL}/contact-form`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        handleSubmitSuccessful();
      })
      .catch((error) => {
        setIsError(true);
      });
  };


  const { t } = useTranslation();
  return (
    <>
      <div className="form-section">
        <div className="container ">
          <div className="gr-12 no-gutter">
            <div className="form-section__wrap">
              <div className="form-section__contact-details">
                <div className="holder">
                  <div className="details">
                    <div className="details__item">
                      <div className="icon">
                        <img src={mapIcon} alt="icon"/>
                      </div>
                      <div className="additional-details">
                        <Heading level="h3">{t("contact.form.office")}</Heading>
                        <p className="text">
                          t AG International Holding, Littauerboden 1, 6014
                          Luzern, Switzerland.
                        </p>
                      </div>
                    </div>
                    <div className="details__item">
                      <div className="icon">
                        <img src={mailIcon} alt="icon"/>
                      </div>
                      <div className="additional-details">
                        <Heading level="h3">{t("contact.form.mail")}</Heading>
                        <Obfuscate className="link" email="info@t-ag.ch" /><br/>
                      </div>
                    </div>
                    <div className="details__item">
                      <div className="icon">
                        <img src={phoneIcon} alt="icon"/>
                      </div>
                      <div className="additional-details">
                        <Heading level="h3">{t("contact.form.phone")}</Heading>
                        <a
                          href="tel:+4141242000"
                          target="_blank"
                          rel="noreferrer"
                          className="link"
                        >+41 41 242 0000
                        </a>
                          <p className="text phone">8:00 – 17:00</p>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-section__form-holder" id="contact-form">
                <Heading level="h2">{t("contact.form.title")}</Heading>
                <div role="form" className="form">
                  <form
                    onSubmit={(e) => {
                      handleSubmit(onSubmit)(e);
                    }}
                    method="POST"
                  >
                    <div className="row" >
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="text"
                            className={errors.name && "error"}
                            {...register("name")}
                            size="40"
                            placeholder={t("contact.form.field.full-name")}
                          />
                          <label htmlFor="name">{t("contact.form.field.full-name")}</label>
                          <label className="error">
                            {errors.name?.message}
                          </label>
                          {errors.name && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="email"
                            className={errors.email && "error"}
                            {...register("email")}
                            size="40"
                            placeholder={t("contact.form.field.email")}
                          />
                          <label htmlFor="email">{t("contact.form.field.email")}</label>
                          <label className="error">
                            {errors.email?.message}
                          </label>
                          {errors.email && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="tel"
                            className={errors.phone && "error"}
                            {...register("phone")}
                            size="40"
                            placeholder={t("contact.form.field.phone-number")}
                          />
                          <label htmlFor="phone">{t("contact.form.field.phone-number")}</label>
                          <label className="error">
                            {errors.phone?.message}
                          </label>
                          {errors.phone && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="text"
                            className={errors.country && "error"}
                            {...register("country")}
                            size="40"
                            placeholder={t("contact.form.field.country")}
                          />
                          <label htmlFor="country">{t("contact.form.field.country")}</label>
                          <label className="error">
                            {errors.country?.message}
                          </label>
                          {errors.country && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="text"
                            className={errors.company && "error"}
                            {...register("company")}
                            size="40"
                            placeholder={t("contact.form.field.company-name")}
                          />
                          <label htmlFor="company">{t("contact.form.field.company-name")}</label>
                          <label className="error">
                            {errors.company?.message}
                          </label>
                          {errors.company && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                      <div className="gr-12 gr-6@md">
                        <fieldset className="form__fieldset">
                          <input
                            type="text"
                            className={errors.subject && "error"}
                            {...register("subject")}
                            size="40"
                            placeholder={t("contact.form.field.subject")}
                          />
                          <label htmlFor="subject">{t("contact.form.field.subject")}</label>
                          <label className="error">
                            {errors.subject?.message}
                          </label>
                          {errors.subject && <div className="error-icon"></div>}
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12">
                        <fieldset className="form__fieldset form__fieldset--about">
                          <textarea
                            {...register("message")}
                            placeholder={t("contact.form.field.about-your-project")}
                            rows="4"
                          />
                          <label htmlFor="message">
                          {t("contact.form.field.about-your-project")}
                          </label>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      <div className="gr-12">
                        <fieldset className="form__fieldset form__fieldset--gdpr">
                          <div className="checkbox-group">
                            <input
                              type="checkbox"
                              {...register("gdpr")}
                              id="gdpr"
                            />
                            <label htmlFor="gdpr">
                            {t("contact.form.field.consent")}
                            </label>
                            <label className="error">
                              {errors.gdpr?.message}
                            </label>
                          </div>
                        </fieldset>
                      </div>
                    </div>
                    <div className="row">
                      {isSuccess && (
                        <div className="gr-12">
                          <div className="form__notification">
                            <span>
                              {t("contact.form.validation.sent")}
                            </span>
                          </div>
                        </div>
                      )}
                      {isError && (
                        <div className="gr-12">
                          <div className="form__error">
                            <span>
                              {t("contact.form.validation.error")}
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="gr-12">
                        <Button
                          type="submit"
                          submitBtn
                          primary
                          withIcon
                          loading={loading}
                          disabled={loading}
                          data-sitekey="6Ld-SrEZAAAAAD9uhrzNWBaTDuQRCTuFQnKI4z54"
                          data-callback="recaptchaCallback"
                          data-badge="inline"
                          data-size="invisible"
                          onClick={() => handleSubmit(onSubmit, onError)}
                        >
                          {loading ? <Trans i18nKey="contact.form.field.sending"></Trans> : <Trans i18nKey="contact.form.field.button"></Trans>}
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FormSection;
