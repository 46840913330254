import React from "react";
import Layout from "../components/layout/layout";
import { graphql} from "gatsby";
import { Hero } from "../components/Hero/Hero";
import { Container } from "../components/Container/Container";
import { Column } from "../components/Column/Column";
import FormSection from '../components/FormSection/FormSection';
import { useTranslation } from "gatsby-plugin-react-i18next";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;


const Contact = () => {
  const { t } = useTranslation();
  return (
    <Layout
      pageTitle={t("contact.hero.title")}
      description={t("contact.hero.subtitle")}
    >
      <Hero
        title={t("contact.hero.title")}
        description={t("contact.hero.subtitle")}
        image="contact/contact-hero.jpg"
        btnLink="#contact-form"
        btnText={t("global.contact-us")}
        withSpacing
      />
      <Container wide>
        <Column
          columns_mobile="12"
          columns_tablet="12"
          columns_desktop="12"
          custom_class="no-gutter@lg"
        >

        <FormSection
          title="Contact us via form"
        />
        </Column>
      </Container>
    </Layout>
  );
};

export default Contact;
